import albinLogo from '../common/images/albinLogo.svg'
import { Grid, Box, Typography, Link } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import '../Albin.css';
import { findText } from '../common/textUtil';
import appStoreBadge from '../common/images/App_Store_Badge_US-UK.svg';
import playStoreBadge from '../common/images/play_en_badge_web_generic.png';
import { greyColor } from '../common/colors';

export default function StartPage({ chosenLanguageId }) {

    const getText = (textName) => {
        return findText(chosenLanguageId, textName)
    }

    const albin_info_1 = getText("albin_info_1")
    const albin_info_2 = getText("albin_info_2")
    const albin_about_1 = getText("albin_about_1")
    const albin_about_2 = getText("albin_about_2")
    const albin_about_3 = getText("albin_about_3")
    const albin_about_4 = getText("albin_about_4")
    const signup_terms = getText("signup_terms")
    const privacy_policy = getText("privacy_policy")
    const email_text = getText("email_text")

    return (
        <Box
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
        // sx={{ border: "1px solid red" }}
        >
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-end"
                alignItems="center"
            // sx={{ border: "1px solid red" }}
            >
                <Grid item xs={12} align="center">
                    <Typography variant="body1">
                        {albin_info_1}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" sx={{ mt: 2, mb: 2 }}>
                    <img src={albinLogo} className="Albin-logo" alt="logo" />
                </Grid>
                <Grid item xs={12} align="center">
                    <Typography variant="body2">
                        {albin_info_2}
                    </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right" sx={{ pr: 1 }}>
                    <a href='https://play.google.com/store/apps/details?id=app.albin.native1'>
                        <img className="Play-logo" alt='Get it on Google Play' src={playStoreBadge} />
                    </a>
                </Grid>
                <Grid item xs={6} textAlign="left" >
                    <a href='https://apps.apple.com/us/app/albin/id1672867331'>
                        <img className="Appstore-logo" alt='Download on the AppStore' src={appStoreBadge} />
                    </a>
                </Grid>
                <Grid item xs={6} textAlign="right" sx={{ pr: 2 }}>
                    <Link to="termsOfService" component={RouterLink}>{signup_terms}</Link>
                </Grid>
                <Grid item xs={6} textAlign="left">
                    <Link to="privacyPolicy" component={RouterLink}>{privacy_policy}</Link>
                </Grid>
                <Grid item xs={12} align="center" sx={{ mt: 4, ml: 1, mr: 1 }}>
                    <Typography variant="caption" sx={{ color: greyColor }}>
                        {albin_about_1}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" sx={{ ml: 1, mr: 1 }}>
                    <Typography variant="caption" sx={{ color: greyColor }}>
                        {albin_about_2}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" sx={{ ml: 1, mr: 1 }}>
                    <Typography variant="caption" sx={{ color: greyColor }}>
                        {albin_about_3}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" sx={{ ml: 1, mr: 1 }}>
                    <Typography variant="caption" sx={{ color: greyColor }}>
                        {albin_about_4}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center" sx={{ ml: 1, mr: 1 }}>
                    <Typography variant="caption" sx={{ color: greyColor }}>
                        {email_text}: support@albin.app
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}