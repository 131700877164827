import { Grid, Box, Typography, Link } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import { PersonAdd } from '@mui/icons-material'
import '../Albin.css';
import { findText } from '../common/textUtil';
import appStoreBadge from '../common/images/App_Store_Badge_US-UK.svg';
import playStoreBadge from '../common/images/play_en_badge_web_generic.png';

export default function AddMe({ chosenLanguageId }) {

    const getText = (textName) => {
        return findText(chosenLanguageId, textName)
    }

    const add_me_info_1 = getText("add_me_info_1")
    const add_me_info_2 = getText("add_me_info_2")
    const go_back = getText("go_back")
    return (
        <Box
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        // sx={{ border: "1px solid red" }}
        >
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-end"
                alignItems="center"
                sx={{ width: "80%" }}
            >
                <Grid item xs={12} align="center">
                    <PersonAdd sx={{ mt: 1 }} />
                    <Typography variant="body1" sx={{ m: 1, color: 'primary.main' }}>
                        {add_me_info_1}
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center">
                    <Typography variant="body1">
                        {add_me_info_2}
                    </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right" sx={{ pr: 1 }}>
                    <a href='https://play.google.com/store/apps/details?id=app.albin.native1'>
                        <img className="Play-logo" alt='Get it on Google Play' src={playStoreBadge} />
                    </a>
                </Grid>
                <Grid item xs={6} textAlign="left" >
                    <a href='https://apps.apple.com/us/app/albin/id1672867331'>
                        <img className="Appstore-logo" alt='Download on the AppStore' src={appStoreBadge} />
                    </a>
                </Grid>
                <Grid item xs={12} align="center">
                    <Link to="/" component={RouterLink}>{go_back}</Link>
                </Grid>
            </Grid>
        </Box>
    );
}
