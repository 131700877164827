import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { yellow, cyan, orange } from '@mui/material/colors'
import languageCache from './common/Languages.json';
import { albinBackgroundColor } from './common/colors';
import { NotFound } from './pages/components/errors';
import StartPage from './pages/StartPage';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ResetPassword from './pages/ResetPassword';
import AddMe from './pages/AddMe'
import DeleteMe from './pages/DeleteMe';

const reload = () => window.location.reload()

let PrimaryLayout = ({ chosenLanguageId }) => (
  <Routes>
    <Route path="/" exact element={<StartPage chosenLanguageId={chosenLanguageId} />} />
    <Route path="/.well-known/assetlinks.json" onEnter={reload} />
    <Route path="/.well-known/apple-app-site-association" onEnter={reload} />
    <Route path="/privacyPolicy" exact element={<PrivacyPolicy chosenLanguageId={chosenLanguageId} />} />
    <Route path="/termsOfService" exact element={<TermsOfService chosenLanguageId={chosenLanguageId} />} />
    <Route path="/deleteMe" exact element={<DeleteMe chosenLanguageId={chosenLanguageId} />} />
    <Route path="/resetPassword/:changePasswordCode" exact element={<ResetPassword chosenLanguageId={chosenLanguageId}/>} />
    <Route path="/addMe/:uniqueAccountId" exact element={<AddMe chosenLanguageId={chosenLanguageId}/>} />
    <Route component={NotFound} />
  </Routes>
)

const getBrowserLanguageIdIfExists = () => {
  let lang = navigator.language || navigator.userLanguage
  if (lang) {
    lang = lang.substring(0, 2)
    lang = lang === 'no' ? 'nb' : lang //correct momentjs lang
    const browserLanguage = languageCache.find(langObj => langObj.iso6391 === lang)
    if (browserLanguage) {
      //console.log(`Found browser language in languages. Id ${browserLanguage.languageId}`)
      return browserLanguage.languageId
    }
  }
  return 2
}

const theme = createTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    mode: 'dark',
    primary: yellow,
    secondary: cyan,
    background: {
      default: albinBackgroundColor
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <CssBaseline />
      <BrowserRouter>
        <PrimaryLayout chosenLanguageId={getBrowserLanguageIdIfExists()} />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
