import { Grid, Box, Typography, Link } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import { Gavel } from '@mui/icons-material'
import { findText } from '../common/textUtil';
import { Helmet } from "react-helmet";

//Not in use anymore
export default function TermsOfService({ chosenLanguageId }) {

    const getText = (textName) => {
        return findText(chosenLanguageId, textName)
    }

    const signup_terms_title = getText("signup_terms_title")
    const signup_terms_body = getText("signup_terms_body")
    const terms_in_app_purchase_1 = getText("terms_in_app_purchase_1")
    const terms_in_app_purchase_2 = getText("terms_in_app_purchase_2")
    const terms_in_app_purchase_3 = getText("terms_in_app_purchase_3")
    const terms_in_app_purchase_4 = getText("terms_in_app_purchase_4")
    const terms_in_app_purchase_5 = getText("terms_in_app_purchase_5")
    const terms_in_app_purchase_6 = getText("terms_in_app_purchase_6")
    const go_back = getText("go_back")
    return (
        <>
            <Helmet>
                <title>{signup_terms_title}</title>
                <link rel="canonical" href="https://albin.app" />
            </Helmet>
            <Box
                height="100vh"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            // sx={{ border: "1px solid red" }}
            >
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    sx={{ width: "80%" }}
                >
                    <Grid item xs={12} align="center">
                        <Gavel sx={{ mt: 1 }} />
                        <Typography variant="h6" sx={{ m: 1, color: 'primary.main' }}>
                            {signup_terms_title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="body1">
                            {signup_terms_body}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="h6" sx={{ m: 1, color: 'primary.main' }}>
                            {terms_in_app_purchase_1}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="body1" >
                            {terms_in_app_purchase_2}
                        </Typography>
                        <Typography variant="body1" >
                            {terms_in_app_purchase_3}
                        </Typography>
                        <Typography variant="body1" >
                            {terms_in_app_purchase_4}
                        </Typography>
                        <Typography variant="body1" >
                            {terms_in_app_purchase_5}
                        </Typography>
                        <Typography variant="body1" >
                            {terms_in_app_purchase_6}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Link to="/" component={RouterLink}>{go_back}</Link>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
