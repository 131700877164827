import { Grid, Box, Typography, Link } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import { Gavel } from '@mui/icons-material'
import { findText } from '../common/textUtil';
import { Helmet } from "react-helmet";

//Not in use anymore
export default function PrivacyPolicy({ chosenLanguageId }) {

    const getText = (textName) => {
        return findText(chosenLanguageId, textName)
    }

    const privacy_policy = getText("privacy_policy")
    const privacy_policy_body_1 = getText("privacy_policy_body_1")
    const privacy_policy_body_2 = getText("privacy_policy_body_2")
    const privacy_policy_body_3 = getText("privacy_policy_body_3")
    const privacy_policy_body_4 = getText("privacy_policy_body_4")
    const privacy_policy_data_deletion_1 = getText("privacy_policy_data_deletion_1")
    const privacy_policy_data_deletion_2 = getText("privacy_policy_data_deletion_2")
    const privacy_policy_data_deletion_3 = getText("privacy_policy_data_deletion_3")

    const go_back = getText("go_back")
    return (
        <>
            <Helmet>
                <title>{privacy_policy}</title>
                <link rel="canonical" href="https://albin.app" />
            </Helmet>
            <Box
                height="100vh"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            // sx={{ border: "1px solid red" }}
            >
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    sx={{ width: "80%" }}
                >
                    <Grid item xs={12} align="center">
                        <Gavel sx={{ mt: 1 }} />
                        <Typography variant="h6" sx={{ m: 1, color: 'primary.main' }}>
                            {privacy_policy}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_body_1}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
                            {privacy_policy_body_2}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_body_3}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_body_4}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="h6" sx={{ m: 1, color: 'primary.main' }}>
                            {privacy_policy_data_deletion_1}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_data_deletion_2}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} align="center">
                        <Typography variant="caption" >
                            {privacy_policy_data_deletion_3}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Link to="/" component={RouterLink}>{go_back}</Link>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
